// eslint-disable-next-line import/no-unused-modules
import "@fontsource/ibm-plex-serif/300.css";
import "@fontsource/ibm-plex-serif/500.css";
import "@fontsource/ibm-plex-serif/700.css";
import "@fontsource/ibm-plex-sans/300.css";
import "@fontsource/ibm-plex-sans/700.css";
import "./src/styles/index.css";

import PiwikPro from "@piwikpro/react-piwik-pro";

// eslint-disable-next-line node/no-process-env
const piwikProId = process.env.GATSBY_PIWIK_PRO_ID;

console.log("%cDesigned by BOND", "font-weight: bold");

export function onClientEntry() {
  console.log("Starting up with piwikProId", piwikProId);
  if (piwikProId) {
    PiwikPro.initialize(piwikProId, "https://mindshift.containers.piwik.pro");
  }
}
